/* Styles for Light/Dark Theming */
$brandColor: #00450f;
$accentGreen: #006600;
$accentOrange: #ff9900;
$accentRed: #cc0000;
$shade1: #000000;
$shade2: #0d0d0d;
$shade3: #1a1a1a;
$shade4: #333333;
$shade5: #808080;
$shade6: #cccccc;
$shade7: #e6e6e6;
$shade8: #f2f2f2;
$shade9: #ffffff;

// icons
$icon-not-selected: #808080;

$mat-label: #7f7f7f;
$selected-field-outline: #d9d9d9;

$disabled-button: #cccccc;

$drag-drop-border: #979797;
$preview-image-icon: #bfbfbf;

// mixin that enables css variables in light mode
@mixin lighten() {
  --shade1: #{$shade1};
  --shade2: #{$shade2};
  --shade3: #{$shade3};
  --shade4: #{$shade4};
  --shade5: #{$shade5};
  --shade6: #{$shade6};
  --shade7: #{$shade7};
  --shade8: #{$shade8};
  --shade9: #{$shade9};
}
// mixin that enables css variables in dark mode
@mixin darken() {
  --shade1: #{$shade9};
  --shade2: #{$shade8};
  --shade3: #{$shade7};
  --shade4: #{$shade6};
  --shade5: #{$shade5};
  --shade6: #{$shade4};
  --shade7: #{$shade3};
  --shade8: #{$shade2};
  --shade9: #{$shade1};
}
