/* You can add global styles to this file, and also import other style files */
@import 'variables.scss';

html,
body {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0px !important;
}
.sidenav {
  visibility: visible !important;
}

.mat-drawer[style*='visibility: hidden'] {
  display: block !important;
  visibility: visible !important;
}

.mat-drawer {
  transform: none !important;
}

.mat-dialog-container {
  background-color: var(--shade9) !important;
}

.mat-drawer-inner-container {
  background-color: var(--shade8) !important;
}

.mat-drawer-content {
  background-color: var(--shade7) !important;
  margin-left: 40px;
}

.mat-paginator-container {
  color: var(--shade1) !important;
}

.mat-paginator-outer-container {
  background-color: var(--shade9) !important;
}

.mat-button-disabled {
  color: var(--shade6) !important;
}

//select
:not(.mat-select-disabled) {
  .mat-select-value {
    color: var(--shade1);
  }
}

.mat-select-disabled {
  .mat-select-value {
    color: var(--shade6);
  }
}

:not(.mat-select-disabled) {
  .mat-select-arrow {
    color: var(--shade1);
  }
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: var(--shade6);
}

.mat-form-field-underline {
  color: var(--shade1) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--shade5) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--shade2) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--shade2) !important;
}

.mat-drawer-side {
  border-right: 0px !important;
}

body.dark {
  @include darken();
}

body.light {
  @include lighten();
}

//checkbox
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--shade1);
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 0 !important;
}

.mat-checkbox-frame {
  border-color: var(--shade6);
}

.mat-checkbox-checkmark-path {
  color: var(--shade9) !important;

  stroke: var(--shade9) !important;
}

//date range
/*.mat-form-field-outline {
  color: var(--shade1) !important;
}*/

.mat-datepicker-toggle-default-icon,
.mat-end-date,
.mat-start-date,
.mat-date-range-input-separator {
  color: var(--shade1) !important;
}

//confirm dialog
#confirm-dialog.mat-dialog-container {
  border-radius: 0 !important;
  box-shadow: 0 1px 0 0 var(--shade7) !important;
}

//dark mode support
.mat-drawer-container {
  background-color: var(--shade8);
  color: var(--shade2);
}

.mat-input-element::placeholder {
  color: var(--shade5);
  opacity: 1;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
.mat-form-field-outline-gap {
  border-top-color: transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: 1px solid var(--shade6) !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 5px 0 0 5px !important;
  border-right-style: none !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-radius: 0.000001px !important;
  border: 1px solid var(--shade6) !important;
  border-left-style: none !important;
  border-right-style: none !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 5px 5px 0 !important;
  border-left-style: none !important;
  flex-grow: 1 !important;
}

.mat-dialog-container {
  border: 1px solid var(--shade6);
}
