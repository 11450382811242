@import './_colors.scss';

::ng-deep {
  //snack-bar
  .mat-snack-bar-container {
    background-color: var(--shade1);
    color: var(--shade9);
  }
  .mat-simple-snackbar-action {
    color: $shade9;
  }

  .success-snackbar {
    background-color: $accentGreen;
    color: $shade9;
  }
  .error-snackbar {
    background-color: $accentRed;
    color: $shade9;
  }
}
